import type {PaymentIntent, PaymentMethod} from '@stripe/stripe-js';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {
  IBasketData,
  ILicenceNames,
  IStripeCheckoutAddresses,
} from '../../definitions/basket';
import {SamiCategory} from '../../definitions/shared';
import {fetchUserDetail} from '../actions';
import {IAction, IStore} from '../store';

type BasketEntityType =
  | 'SheetMusic'
  | 'HardCopySheetMusic'
  | 'Mp3'
  | 'Mp3Album';

export const FETCH_BASKET_STARTED = 'basket/fetchStarted';
function fetchBasketStarted(): IAction {
  return {
    type: FETCH_BASKET_STARTED,
  };
}
export const FETCH_BASKET_RECEIVE = 'basket/fetchBasketReceive';
function fetchBasketReceive(data: IBasketData): IAction {
  return {
    data,
    type: FETCH_BASKET_RECEIVE,
  };
}
export const FETCH_BASKET_ERROR = 'basket/fetchBasketError';
function fetchBasketError(): IAction {
  return {
    type: FETCH_BASKET_ERROR,
  };
}
export function fetchBasket(): ThunkAction<
  PromiseLike<any>,
  IStore,
  null,
  IAction
> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchBasketStarted());
    return fetch(`/api/basket/`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        return resp.json().then((data) => {
          dispatch(fetchBasketReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchBasketError());
        console.error(`Error parsing basket data: ${err.stack}`);
      });
  };
}

export function addBrassToBasket(
  id: number,
  type: BasketEntityType,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return addToBasket(id, type, 'brass');
}

export function addVocalToBasket(
  id: number,
  type: BasketEntityType,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return addToBasket(id, type, 'vocal');
}

export const BASKET_ADD_STARTED = 'basket/addStarted';
function basketAddStarted(id: number, category: SamiCategory): IAction {
  return {
    data: {id, category},
    type: BASKET_ADD_STARTED,
  };
}
export const BASKET_ADD_FINISHED = 'basket/addFinished';
function basketAddFinished(id: number, category: SamiCategory): IAction {
  return {
    data: {id, category},
    type: BASKET_ADD_FINISHED,
  };
}
export const BASKET_ADD_ERROR = 'basket/addError';
function basketAddError(): IAction {
  return {
    type: BASKET_ADD_ERROR,
  };
}
function addToBasket(
  id: number,
  type: BasketEntityType,
  category: SamiCategory,
) {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(basketAddStarted(id, category));

    return fetch(`/api/basket`, {
      body: JSON.stringify({
        [`${category}${type}Id`]: id,
      }),
      credentials: 'same-origin',
      method: 'POST',
    })
      .then((resp: Response) => {
        return resp.json().then((data) => {
          dispatch(basketAddFinished(id, category));
          dispatch(fetchBasketReceive(data));
        });
      })
      .catch((err) => {
        dispatch(basketAddError());
        console.error(`Error adding to basket: ${err.stack}`);
      });
  };
}

export function removeBrassFromBasket(
  id: number,
  type: BasketEntityType,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return removeFromBasket(id, type, 'brass');
}

export function removeVocalFromBasket(
  id: number,
  type: BasketEntityType,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return removeFromBasket(id, type, 'vocal');
}

export const BASKET_REMOVE_STARTED = 'basket/removeStarted';
function basketRemoveStarted(id: number, category: SamiCategory): IAction {
  return {
    data: {id, category},
    type: BASKET_REMOVE_STARTED,
  };
}
export const BASKET_REMOVE_FINISHED = 'basket/removeFinished';
function basketRemoveFinished(id: number, category: SamiCategory): IAction {
  return {
    data: {id, category},
    type: BASKET_REMOVE_FINISHED,
  };
}
export const BASKET_REMOVE_ERROR = 'basket/removeError';
function basketRemoveError(): IAction {
  return {
    type: BASKET_REMOVE_ERROR,
  };
}
function removeFromBasket(
  id: number,
  type: BasketEntityType,
  category: SamiCategory,
) {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(basketRemoveStarted(id, category));

    return fetch(`/api/basket`, {
      body: JSON.stringify({
        [`${category}${type}Id`]: id,
      }),
      credentials: 'same-origin',
      method: 'DELETE',
    })
      .then((resp: Response) => {
        return resp.json().then((data) => {
          dispatch(basketRemoveFinished(id, category));
          dispatch(fetchBasketReceive(data));
        });
      })
      .catch((err) => {
        dispatch(basketRemoveError());
        console.error(`Error adding to basket: ${err.stack}`);
      });
  };
}

export function applyVoucher(
  code: string,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchBasketStarted());

    return fetch(`/api/basket/voucher`, {
      body: JSON.stringify({
        code,
      }),
      credentials: 'same-origin',
      method: 'POST',
    })
      .then((resp: Response) => {
        return resp.json().then((data) => {
          dispatch(fetchBasketReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchBasketError());
        console.error(`Error applying voucher: ${err.stack}`);
      });
  };
}

export function removeVoucher(): ThunkAction<
  PromiseLike<any>,
  IStore,
  null,
  IAction
> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchBasketStarted());

    return fetch(`/api/basket/remove-voucher`, {
      credentials: 'same-origin',
      method: 'POST',
    })
      .then((resp: Response) => {
        return resp.json().then((data) => {
          dispatch(fetchBasketReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchBasketError());
        console.error(`Error removing voucher: ${err.stack}`);
      });
  };
}

export const CHECKOUT_STARTED = 'basket/checkoutStarted';
function checkoutStarted(): IAction {
  return {
    type: CHECKOUT_STARTED,
  };
}
export const CHECKOUT_ERROR = 'basket/checkoutError';
export function checkoutError(message?: string): IAction {
  return {
    message,
    type: CHECKOUT_ERROR,
  };
}
export const CHECKOUT_ACTION_REQUIRED = 'basket/checkoutActionRequired';
function checkoutActionRequired(clientSecret: string): IAction {
  return {
    data: {clientSecret},
    type: CHECKOUT_ACTION_REQUIRED,
  };
}

export const CHECKOUT_FINISHED = 'basket/checkoutFinished';
function checkoutFinished(): IAction {
  return {
    type: CHECKOUT_FINISHED,
  };
}
export function checkout(
  paymentMethod: PaymentMethod,
  shippingCost: number,
  addresses: IStripeCheckoutAddresses,
  licenceNames: ILicenceNames,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(checkoutStarted());

    return fetch(`/api/checkout/`, {
      body: JSON.stringify({
        addresses,
        licenceNames,
        paymentMethod,
        shippingCost,
      }),
      credentials: 'same-origin',
      method: 'POST',
    }).then((resp: Response) => {
      return resp
        .json()
        .then((data) => {
          if (!resp.ok) {
            dispatch(checkoutError(data.msg));
            return;
          }

          handleStripeResponse(data, dispatch);
        })
        .catch((err) => {
          dispatch(checkoutError());
          console.error(`Error parsing checkout data: ${err.stack}`);
        });
    });
  };
}

export function checkPayment(
  paymentIntent: PaymentIntent,
  addresses: IStripeCheckoutAddresses,
  licenceNames: ILicenceNames,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    return fetch(`/api/check-payment/`, {
      body: JSON.stringify({
        addresses,
        licenceNames,
        paymentIntent,
      }),
      credentials: 'same-origin',
      method: 'POST',
    }).then((resp: Response) => {
      return resp
        .json()
        .then((data) => {
          if (!resp.ok) {
            dispatch(checkoutError(data.msg));
            return;
          }

          handleStripeResponse(data, dispatch);
        })
        .catch((err) => {
          dispatch(checkoutError());
          console.error(`Error parsing checkout data: ${err.stack}`);
        });
    });
  };
}

export function freeCheckout(
  licenceNames: ILicenceNames,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(checkoutStarted());

    return fetch(`/api/free-checkout/`, {
      body: JSON.stringify({
        licenceNames,
      }),
      credentials: 'same-origin',
      method: 'POST',
    }).then((resp: Response) => {
      return resp
        .json()
        .then((data) => {
          if (!resp.ok) {
            dispatch(checkoutError(data.msg));
            return;
          }

          handleStripeResponse(data, dispatch);
        })
        .catch((err) => {
          dispatch(checkoutError());
          console.error(`Error parsing checkout data: ${err.stack}`);
        });
    });
  };
}

function handleStripeResponse(
  data: any,
  dispatch: ThunkDispatch<IStore, null, IAction>,
) {
  switch (data.status) {
    case 'requires_action': {
      dispatch(checkoutActionRequired(data.clientSecret));
      break;
    }

    case 'succeeded': {
      dispatch(fetchUserDetail()); // Refetch data for new purchases
      dispatch(checkoutFinished());
      break;
    }

    default: {
      dispatch(checkoutError());
      console.error(
        `Error in checkout: didn't understand PaymentIntent status: ${data.status}`,
      );
    }
  }
}
