import React from 'react';
import Footer from './components/footer';
import Main from './main';
import Meta from './components/meta';

import './App.scss';

const App: React.FunctionComponent<{}> = () => (
  <div>
    <Meta // Duplicated server-side
      title="Salvation Army Music Index"
      description="An index of published Salvation Army music"
    />
    <Main />
    <Footer />
  </div>
);

export default App;
