interface IUrlArgs {
  [index: string]: any;
}

export default function getUrlArgs(): IUrlArgs {
  let url = window.location.search;
  const rplus = /\+/g;
  const rsearch = /([^&=]+)=?([^&]*)/g;
  const start = url.indexOf('?');
  const values: IUrlArgs = {};

  if (start !== -1) {
    url = url.substring(start + 1);
  }

  const matches = url.match(rsearch);
  if (!matches) {
    return {};
  }

  let match = rsearch.exec(url);
  while (match) {
    const key = decodeURIComponent(match[1].replace(rplus, ' '));
    const value = decodeURIComponent(match[2].replace(rplus, ' '));

    if (key in values) {
      if (!Array.isArray(values[key])) {
        values[key] = [values[key]];
      }

      values[key].push(value);
    } else {
      values[key] = value;
    }

    match = rsearch.exec(url);
  }

  return values;
}
