import {IBrassMp3Album} from './brass';
import {SamiCategory} from './shared';
import {IVocalMp3Album} from './vocal';

export enum HallOfFameStatus {
  PRE_RELEASE = 0,
  OPEN,
  REMINDER_SENT,
  CLOSED,
  RESULTS_50_TO_41,
  RESULTS_50_TO_31,
  RESULTS_50_TO_21,
  RESULTS_50_TO_11,
  RESULTS_50_TO_4,
  RESULTS_50_TO_3,
  RESULTS_50_TO_2,
  RESULTS_RELEASED,
  ALBUM_RELEASED,
  COMPLETED,
}

export interface IHallOfFameStatus {
  id: HallOfFameStatus;
  name: string;
  next_status_description?: string | null;
}

const statusLength = Object.keys(HallOfFameStatus).length;
const hallOfFameStatuses: IHallOfFameStatus[] = [];
// Get all string keys from enum
for (let i = 0; i < statusLength / 2; i++) {
  hallOfFameStatuses.push({
    id: i,
    name: HallOfFameStatus[i],
  });
}

export {hallOfFameStatuses};

export interface IHallOfFame {
  id: number;
  hallOfFameStatus: IHallOfFameStatus;
  brassMp3Album?: IBrassMp3Album;
  filter_fn?: string | null;
  special: string | null;
  title: string;
  vocalMp3Album?: IVocalMp3Album;
  year: number;
  youtube_id: string | null;
  previous_hall_of_fame_id: number | null;
}

export type HallsOfFame = Record<SamiCategory, Record<string, IHallOfFame>>;

export interface IHallOfFameRow {
  pieceId: number;
  title: string;
  getDisplayValue(): string;
}

export interface IHallOfFameVote {
  pieceId: number;
  position: number;
}

export class BrassHallOfFameRow implements IHallOfFameRow {
  public arranger: string;
  public composer: string;
  public pieceId: number;
  public series: string;
  public title: string;
  public type: string;
  public year: number;
  public constructor(options: {
    arranger: string;
    composer: string;
    pieceId: number;
    series: string;
    title: string;
    type: string;
    year: number;
  }) {
    this.arranger = options.arranger;
    this.composer = options.composer;
    this.pieceId = options.pieceId;
    this.series = options.series;
    this.title = options.title;
    this.type = options.type;
    this.year = options.year;
  }
  public getDisplayValue() {
    let rval = `${this.type} - ${this.title} by ${this.composer}`;
    if (this.arranger) {
      rval += ` arr. ${this.arranger}`;
    }
    return rval;
  }
}

// tslint:disable:max-classes-per-file
export class VocalHallOfFameRow implements IHallOfFameRow {
  public arranger: string;
  public composer: string;
  public lyricist: string;
  public pieceId: number;
  public series: string;
  public title: string;
  public constructor(options: {
    arranger: string;
    composer: string;
    lyricist: string;
    pieceId: number;
    series: string;
    title: string;
  }) {
    this.arranger = options.arranger;
    this.composer = options.composer;
    this.lyricist = options.lyricist;
    this.pieceId = options.pieceId;
    this.series = options.series;
    this.title = options.title;
  }
  public getDisplayValue() {
    let rval = `${this.title} by ${this.lyricist} / ${this.composer}`;
    if (this.arranger) {
      rval += ` arr. ${this.arranger}`;
    }
    return rval;
  }
}

export interface IHallOfFamePublicationInfo {
  id: number;
  title: string;
}

export interface IHallOfFamePieceInfo {
  composer: string;
  id: number;
  title: string;
}

export interface IBrassHallOfFameResult {
  brassPiece: IHallOfFamePieceInfo;
  brassPublications: IHallOfFamePublicationInfo[];
  displayRanking?: string | number;
  points: number;
}

export interface IVocalHallOfFameResult {
  displayRanking?: string | number;
  points: number;
  vocalPiece: IHallOfFamePieceInfo;
  vocalPublications: IHallOfFamePublicationInfo[];
}

export interface IHallOfFameReportWrapper<
  T extends IBrassHallOfFameResult[] | IVocalHallOfFameResult[],
> {
  numVoters: number;
  numVotes: number;
  results: T;
}

export interface IHallsOfFameReports {
  brass?: Record<string, IHallOfFameReportWrapper<IBrassHallOfFameResult[]>>;
  vocal?: Record<string, IHallOfFameReportWrapper<IVocalHallOfFameResult[]>>;
}

interface IHallOfFameResultsWrapper<
  T extends IBrassHallOfFameResult[] | IVocalHallOfFameResult[],
> {
  results: T;
  title: string;
  year: string;
}

export interface IHallsOfFameResults {
  brass?: Record<string, IHallOfFameResultsWrapper<IBrassHallOfFameResult[]>>;
  vocal?: Record<string, IHallOfFameResultsWrapper<IVocalHallOfFameResult[]>>;
}

export interface IPublicationHallOfFamePlace {
  displayRanking: string | number;
  link: string;
  title: string;
}
