import {
  IChartsData,
  ISalesData,
  ISalesOverview,
  IStatsData,
  IJournalsData,
  StatsKey,
  IVouchersData,
} from '../../definitions/admin';
import {
  FETCH_CHARTS_DATA_ERROR,
  FETCH_CHARTS_DATA_RECEIVE,
  FETCH_CHARTS_DATA_STARTED,
  FETCH_CHARTS_DATA_UNAUTHORISED,
  FETCH_SALES_DATA_ERROR,
  FETCH_SALES_DATA_RECEIVE,
  FETCH_SALES_DATA_STARTED,
  FETCH_SALES_DATA_UNAUTHORISED,
  FETCH_SALES_OVERVIEW_ERROR,
  FETCH_SALES_OVERVIEW_RECEIVE,
  FETCH_SALES_OVERVIEW_STARTED,
  FETCH_SALES_OVERVIEW_UNAUTHORISED,
  FETCH_STATS_DATA_ERROR,
  FETCH_STATS_DATA_RECEIVE,
  FETCH_STATS_DATA_STARTED,
  FETCH_STATS_DATA_UNAUTHORISED,
  FETCH_JOURNALS_DATA_ERROR,
  FETCH_JOURNALS_DATA_RECEIVE,
  FETCH_JOURNALS_DATA_STARTED,
  FETCH_JOURNALS_DATA_UNAUTHORISED,
  FETCH_VOUCHERS_DATA_ERROR,
  FETCH_VOUCHERS_DATA_RECEIVE,
  FETCH_VOUCHERS_DATA_STARTED,
  FETCH_VOUCHERS_DATA_UNAUTHORISED,
  CHARTS_DATA_RESET,
  REFRESH_CACHE_ERROR,
  REFRESH_CACHE_STARTED,
  REFRESH_CACHE_UNAUTHORISED,
  SALES_DATA_RESET,
  STATS_DATA_RESET,
  SUBSCRIPTIONS_GENERATE_VOUCHER_ERROR,
  SUBSCRIPTIONS_GENERATE_VOUCHER_RECEIVE,
  SUBSCRIPTIONS_GENERATE_VOUCHER_STARTED,
  REFRESH_CACHE_FINISHED,
} from '../actions';
import {IAction} from '../store';

export interface IAdminState {
  cacheManagementData: {
    error: string;
    fetching: boolean;
  };
  chartsData: {
    detail: IChartsData | null;
    error: boolean;
    fetching: boolean;
  };
  salesData: {
    detail: ISalesData | null;
    error: boolean;
    fetching: boolean;
  };
  salesOverview: {
    detail: ISalesOverview | null;
    error: boolean;
    fetching: boolean;
  };
  statsData: {
    detail: IStatsData | null;
    error: boolean;
    fetching?: StatsKey | 'overview';
  };
  subscriptionsData: {
    journals: {
      error: boolean;
      detail: IJournalsData | null;
      fetching: boolean;
    };
    voucherCreation: {
      detail: string | null;
      error: boolean;
      fetching: boolean;
    };
    vouchers: {
      detail: IVouchersData | null;
      error: boolean;
      fetching: boolean;
    };
  };
}

export const initialState: IAdminState = {
  cacheManagementData: {
    error: '',
    fetching: false,
  },
  chartsData: {
    detail: null,
    error: false,
    fetching: false,
  },
  salesData: {
    detail: null,
    error: false,
    fetching: false,
  },
  salesOverview: {
    detail: null,
    error: false,
    fetching: false,
  },
  statsData: {
    detail: null,
    error: false,
    fetching: undefined,
  },
  subscriptionsData: {
    journals: {
      detail: null,
      error: false,
      fetching: false,
    },
    vouchers: {
      detail: null,
      error: false,
      fetching: false,
    },
    voucherCreation: {
      detail: null,
      error: false,
      fetching: false,
    },
  },
};

export default function adminReducer(
  state = initialState,
  action: IAction,
): IAdminState {
  switch (action.type) {
    case FETCH_SALES_DATA_ERROR:
      return {
        ...state,
        salesData: {
          detail: null,
          error: true,
          fetching: false,
        },
      };

    case FETCH_SALES_DATA_RECEIVE:
      return {
        ...state,
        salesData: {
          detail: action.data,
          error: false,
          fetching: false,
        },
      };

    case FETCH_SALES_DATA_STARTED:
      return {
        ...state,
        salesData: {
          detail: null,
          error: false,
          fetching: true,
        },
      };

    case FETCH_SALES_DATA_UNAUTHORISED:
      return {
        ...state,
        salesData: {
          detail: null,
          error: true,
          fetching: false,
        },
      };

    case SALES_DATA_RESET:
      return {
        ...state,
        salesData: {
          detail: null,
          error: false,
          fetching: false,
        },
      };

    case FETCH_SALES_OVERVIEW_ERROR:
      return {
        ...state,
        salesOverview: {
          detail: null,
          error: true,
          fetching: false,
        },
      };

    case FETCH_SALES_OVERVIEW_RECEIVE:
      return {
        ...state,
        salesOverview: {
          detail: action.data,
          error: false,
          fetching: false,
        },
      };

    case FETCH_SALES_OVERVIEW_STARTED:
      return {
        ...state,
        salesOverview: {
          detail: null,
          error: false,
          fetching: true,
        },
      };

    case FETCH_SALES_OVERVIEW_UNAUTHORISED:
      return {
        ...state,
        salesOverview: {
          detail: null,
          error: true,
          fetching: false,
        },
      };

    case FETCH_STATS_DATA_ERROR:
      return {
        ...state,
        statsData: {
          detail: null,
          error: true,
          fetching: undefined,
        },
      };

    case FETCH_STATS_DATA_RECEIVE:
      return {
        ...state,
        statsData: {
          detail: {
            ...(state.statsData.detail as IStatsData),
            [action.section]: action.data,
          },
          error: false,
          fetching: undefined,
        },
      };

    case FETCH_STATS_DATA_STARTED:
      return {
        ...state,
        statsData: {
          detail: state.statsData.detail,
          error: false,
          fetching: action.section,
        },
      };

    case FETCH_STATS_DATA_UNAUTHORISED:
      return {
        ...state,
        statsData: {
          detail: null,
          error: true,
          fetching: undefined,
        },
      };

    case STATS_DATA_RESET:
      return {
        ...state,
        statsData: {
          detail: null,
          error: false,
          fetching: undefined,
        },
      };

    case FETCH_CHARTS_DATA_ERROR:
      return {
        ...state,
        chartsData: {
          detail: null,
          error: true,
          fetching: false,
        },
      };

    case FETCH_CHARTS_DATA_RECEIVE:
      return {
        ...state,
        chartsData: {
          detail: action.data,
          error: false,
          fetching: false,
        },
      };

    case FETCH_CHARTS_DATA_STARTED:
      return {
        ...state,
        chartsData: {
          detail: state.chartsData.detail,
          error: false,
          fetching: true,
        },
      };

    case FETCH_CHARTS_DATA_UNAUTHORISED:
      return {
        ...state,
        chartsData: {
          detail: null,
          error: true,
          fetching: false,
        },
      };

    case CHARTS_DATA_RESET:
      return {
        ...state,
        chartsData: {
          detail: null,
          error: false,
          fetching: false,
        },
      };

    case FETCH_JOURNALS_DATA_ERROR:
      return {
        ...state,
        subscriptionsData: {
          ...state.subscriptionsData,
          journals: {
            detail: null,
            error: true,

            fetching: false,
          },
        },
      };

    case FETCH_JOURNALS_DATA_RECEIVE:
      return {
        ...state,
        subscriptionsData: {
          ...state.subscriptionsData,
          journals: {
            detail: action.data,
            error: false,
            fetching: false,
          },
        },
      };

    case FETCH_JOURNALS_DATA_STARTED:
      return {
        ...state,
        subscriptionsData: {
          ...state.subscriptionsData,
          journals: {
            detail: null,
            error: false,
            fetching: true,
          },
        },
      };

    case FETCH_JOURNALS_DATA_UNAUTHORISED:
      return {
        ...state,
        subscriptionsData: {
          ...state.subscriptionsData,
          journals: {
            detail: null,
            error: true,
            fetching: false,
          },
        },
      };

    case FETCH_VOUCHERS_DATA_ERROR:
      return {
        ...state,
        subscriptionsData: {
          ...state.subscriptionsData,
          vouchers: {
            detail: null,
            error: true,
            fetching: false,
          },
        },
      };

    case FETCH_VOUCHERS_DATA_RECEIVE:
      return {
        ...state,
        subscriptionsData: {
          ...state.subscriptionsData,
          vouchers: {
            detail: action.data,
            error: false,
            fetching: false,
          },
        },
      };

    case FETCH_VOUCHERS_DATA_STARTED:
      return {
        ...state,
        subscriptionsData: {
          ...state.subscriptionsData,
          vouchers: {
            detail: null,
            error: false,
            fetching: true,
          },
        },
      };

    case FETCH_VOUCHERS_DATA_UNAUTHORISED:
      return {
        ...state,
        subscriptionsData: {
          ...state.subscriptionsData,
          vouchers: {
            detail: null,
            error: true,
            fetching: false,
          },
        },
      };

    case SUBSCRIPTIONS_GENERATE_VOUCHER_STARTED:
      return {
        ...state,
        subscriptionsData: {
          ...state.subscriptionsData,
          voucherCreation: {
            detail: null,
            error: false,
            fetching: true,
          },
        },
      };

    case SUBSCRIPTIONS_GENERATE_VOUCHER_ERROR:
    case SUBSCRIPTIONS_GENERATE_VOUCHER_RECEIVE:
      return {
        ...state,
        subscriptionsData: {
          ...state.subscriptionsData,
          voucherCreation: {
            detail: action.data,
            error: false,
            fetching: false,
          },
        },
      };

    case REFRESH_CACHE_UNAUTHORISED:
      return {
        ...state,
        cacheManagementData: {
          error: `Sorry, you are not authorised to refresh the cache`,
          fetching: false,
        },
      };

    case REFRESH_CACHE_ERROR:
      return {
        ...state,
        cacheManagementData: {
          error:
            'Sorry, an error occurred trying to refetch the data. Please try again later.',
          fetching: false,
        },
      };

    case REFRESH_CACHE_STARTED:
      return {
        ...state,
        cacheManagementData: {
          error: '',
          fetching: true,
        },
      };

    case REFRESH_CACHE_FINISHED:
      return {
        ...state,
        cacheManagementData: {
          error: '',
          fetching: false,
        },
      };

    default:
      return state;
  }
}
