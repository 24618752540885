import React from 'react';

import './spinner.scss';

const Spinner: React.FunctionComponent<{}> = () => {
  const classes = ['spinner', 'animated'];

  return (
    <div className="loading" role="spinbutton" data-testid="loading-spinner">
      Loading, Please Wait...
      <div className={classes.join(' ')} />
    </div>
  );
};

export default Spinner;
