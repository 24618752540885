import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from '@redux-devtools/extension';
import {thunk} from 'redux-thunk';
import reducer, {
  IAdminState,
  IBasketState,
  IBrassState,
  IDomState,
  IExternalApiState,
  IHallOfFameState,
  initialAdminState,
  initialBasketState,
  initialBrassState,
  initialDomState,
  initialExternalApiState,
  initialHallOfFameState,
  initialUserState,
  initialVocalState,
  IUserState,
  IVocalState,
} from './reducer';

export interface IAction {
  [index: string]: any;
  type: string;
}

export interface IStore {
  admin: IAdminState;
  basket: IBasketState;
  brass: IBrassState;
  dom: IDomState;
  externalApis: IExternalApiState;
  hallOfFame: IHallOfFameState;
  user: IUserState;
  vocal: IVocalState;
}

export const initialState: IStore = {
  admin: initialAdminState,
  basket: initialBasketState,
  brass: initialBrassState,
  dom: initialDomState,
  externalApis: initialExternalApiState,
  hallOfFame: initialHallOfFameState,
  user: initialUserState,
  vocal: initialVocalState,
};

const store = createStore<IStore, IAction, {}, {}>(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk)),
);

export default store;
