import {
  HallsOfFame,
  IHallsOfFameReports,
  IHallsOfFameResults,
} from '../../definitions/hallOfFame';
import {
  FETCH_HALL_OF_FAME_DATA_ERROR,
  FETCH_HALL_OF_FAME_DATA_RECEIVE,
  FETCH_HALL_OF_FAME_DATA_STARTED,
  FETCH_HALL_OF_FAME_REPORT_ERROR,
  FETCH_HALL_OF_FAME_REPORT_RECEIVE,
  FETCH_HALL_OF_FAME_REPORT_STARTED,
  FETCH_HALL_OF_FAME_RESULTS_ERROR,
  FETCH_HALL_OF_FAME_RESULTS_RECEIVE,
  FETCH_HALL_OF_FAME_RESULTS_STARTED,
  HALL_OF_FAME_ADVANCE_ERROR,
  HALL_OF_FAME_ADVANCE_STARTED,
  HALL_OF_FAME_ADVANCE_SUCCESS,
} from '../actions';
import {IAction} from '../store';

export interface IHallOfFameState {
  hallOfFameStatusData: {
    error: boolean;
    fetching: boolean;
  };
  hallsOfFameData: {
    detail: HallsOfFame;
    error: boolean;
    fetching: boolean;
  };
  hallsOfFameReports: {
    detail: IHallsOfFameReports;
    error: boolean;
    fetching: boolean;
  };
  hallsOfFameResults: {
    detail: IHallsOfFameResults;
    error: boolean;
    fetching: boolean;
  };
}

export const initialState: IHallOfFameState = {
  hallOfFameStatusData: {
    error: false,
    fetching: false,
  },
  hallsOfFameData: {
    detail: {
      brass: {},
      vocal: {},
    },
    error: false,
    fetching: false,
  },
  hallsOfFameReports: {
    detail: {
      brass: {},
      vocal: {},
    },
    error: false,
    fetching: false,
  },
  hallsOfFameResults: {
    detail: {
      brass: {},
      vocal: {},
    },
    error: false,
    fetching: false,
  },
};

export default function hallOfFameReducer(
  state = initialState,
  action: IAction,
): IHallOfFameState {
  switch (action.type) {
    case FETCH_HALL_OF_FAME_DATA_STARTED: {
      return {
        ...state,
        hallsOfFameData: {
          ...state.hallsOfFameData,
          error: false,
          fetching: true,
        },
      };
    }

    case FETCH_HALL_OF_FAME_DATA_RECEIVE: {
      return {
        ...state,
        hallsOfFameData: {
          detail: action.data,
          error: false,
          fetching: false,
        },
      };
    }

    case FETCH_HALL_OF_FAME_DATA_ERROR: {
      return {
        ...state,
        hallsOfFameData: {
          ...state.hallsOfFameData,
          error: true,
          fetching: false,
        },
      };
    }

    case FETCH_HALL_OF_FAME_REPORT_STARTED: {
      return {
        ...state,
        hallsOfFameReports: {
          ...state.hallsOfFameReports,
          error: false,
          fetching: true,
        },
      };
    }

    case FETCH_HALL_OF_FAME_REPORT_RECEIVE: {
      return {
        ...state,
        hallsOfFameReports: {
          detail: action.data,
          error: false,
          fetching: false,
        },
      };
    }

    case FETCH_HALL_OF_FAME_REPORT_ERROR: {
      return {
        ...state,
        hallsOfFameReports: {
          ...state.hallsOfFameReports,
          error: true,
          fetching: false,
        },
      };
    }

    case FETCH_HALL_OF_FAME_RESULTS_STARTED: {
      return {
        ...state,
        hallsOfFameResults: {
          ...state.hallsOfFameResults,
          error: false,
          fetching: true,
        },
      };
    }

    case FETCH_HALL_OF_FAME_RESULTS_RECEIVE: {
      return {
        ...state,
        hallsOfFameResults: {
          detail: {
            brass: {
              ...state.hallsOfFameResults.detail.brass,
              ...action.data.brass,
            },
            vocal: {
              ...state.hallsOfFameResults.detail.vocal,
              ...action.data.vocal,
            },
          },
          error: false,
          fetching: false,
        },
      };
    }

    case FETCH_HALL_OF_FAME_RESULTS_ERROR: {
      return {
        ...state,
        hallsOfFameResults: {
          ...state.hallsOfFameResults,
          error: true,
          fetching: false,
        },
      };
    }

    case HALL_OF_FAME_ADVANCE_ERROR:
      return {
        ...state,
        hallOfFameStatusData: {
          error: true,
          fetching: false,
        },
      };

    case HALL_OF_FAME_ADVANCE_STARTED:
      return {
        ...state,
        hallOfFameStatusData: {
          error: false,
          fetching: true,
        },
      };

    case HALL_OF_FAME_ADVANCE_SUCCESS:
      return {
        ...state,
        hallOfFameStatusData: {
          error: false,
          fetching: false,
        },
      };

    default:
      return state;
  }
}
