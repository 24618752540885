import {IUser} from '../definitions/user';

export function pendoLoginAnonymous() {
  pendo.initialize();
}

export function pendoLogin(user: IUser) {
  pendo.identify({
    visitor: {
      id: `${user.id}`,
      email: user.email,
      full_name: user.name,
      isAdmin: user.isAdmin,
    },
    account: {
      id: 'customers',
      name: 'All Customers',
    },
  });
}
