import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {
  HallsOfFame,
  IBrassHallOfFameResult,
  IHallOfFameReportWrapper,
  IHallOfFameVote,
  IHallsOfFameReports,
  IHallsOfFameResults,
  IVocalHallOfFameResult,
} from '../../definitions/hallOfFame';
import {SamiCategory} from '../../definitions/shared';
import {IAction, IStore} from '../store';
import {fetchUserDetail} from '../user/actions';

export const FETCH_HALL_OF_FAME_DATA_STARTED = 'hallOfFame/fetchDataStarted';
function fetchHallOfFameDataStarted(): IAction {
  return {
    type: FETCH_HALL_OF_FAME_DATA_STARTED,
  };
}
export const FETCH_HALL_OF_FAME_DATA_RECEIVE = 'hallOfFame/fetchDataReceive';
function fetchHallOfFameDataReceive(data: HallsOfFame): IAction {
  return {
    data,
    type: FETCH_HALL_OF_FAME_DATA_RECEIVE,
  };
}
export const FETCH_HALL_OF_FAME_DATA_ERROR = 'hallOfFame/fetchDataError';
function fetchHallOfFameDataError(): IAction {
  return {
    type: FETCH_HALL_OF_FAME_DATA_ERROR,
  };
}

export function fetchHallOfFameData(): ThunkAction<
  PromiseLike<void>,
  IStore,
  null,
  IAction
> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchHallOfFameDataStarted());

    return fetch('/api/hall-of-fame', {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchHallOfFameDataError());
          console.error(`Error fetching hall of fame data: ${resp.status}`);
          return;
        }
        return resp.json().then((data: HallsOfFame) => {
          dispatch(fetchHallOfFameDataReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchHallOfFameDataError());
        console.error(`Error parsing hall of fame data: ${err.stack}`);
      });
  };
}

export const FETCH_HALL_OF_FAME_REPORT_STARTED =
  'hallOfFame/fetchReportStarted';
function fetchHallOfFameReportStarted(): IAction {
  return {
    type: FETCH_HALL_OF_FAME_REPORT_STARTED,
  };
}
export const FETCH_HALL_OF_FAME_REPORT_RECEIVE =
  'hallOfFame/fetchReportReceive';
function fetchHallOfFameReportReceive(data: IHallsOfFameReports): IAction {
  return {
    data,
    type: FETCH_HALL_OF_FAME_REPORT_RECEIVE,
  };
}
export const FETCH_HALL_OF_FAME_REPORT_ERROR = 'hallOfFame/fetchReportError';
function fetchHallOfFameReportError(): IAction {
  return {
    type: FETCH_HALL_OF_FAME_REPORT_ERROR,
  };
}

export function fetchBrassHallOfFameReport(
  hallOfFameId: number,
): ThunkAction<PromiseLike<void>, IStore, null, IAction> {
  return fetchHallOfFameReport(hallOfFameId, 'brass');
}

export function fetchVocalHallOfFameReport(
  hallOfFameId: number,
): ThunkAction<PromiseLike<void>, IStore, null, IAction> {
  return fetchHallOfFameReport(hallOfFameId, 'vocal');
}

export function fetchHallOfFameReport(
  hallOfFameId: number,
  category: SamiCategory,
): ThunkAction<PromiseLike<void>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchHallOfFameReportStarted());

    return fetch(`/api/hall-of-fame/${category}/report/${hallOfFameId}`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchHallOfFameReportError());
          console.error(`Error fetching hall of fame report: ${resp.status}`);
          return;
        }
        return resp
          .json()
          .then(
            (
              data:
                | IHallOfFameReportWrapper<IBrassHallOfFameResult[]>
                | IHallOfFameReportWrapper<IVocalHallOfFameResult[]>,
            ) => {
              dispatch(
                fetchHallOfFameReportReceive({
                  [category]: {
                    [hallOfFameId]: data,
                  },
                }),
              );
            },
          );
      })
      .catch((err) => {
        dispatch(fetchHallOfFameReportError());
        console.error(`Error parsing hall of fame report: ${err.stack}`);
      });
  };
}

export const FETCH_HALL_OF_FAME_RESULTS_STARTED =
  'hallOfFame/fetchResultsStarted';
function fetchHallOfFameResultsStarted(): IAction {
  return {
    type: FETCH_HALL_OF_FAME_RESULTS_STARTED,
  };
}
export const FETCH_HALL_OF_FAME_RESULTS_RECEIVE =
  'hallOfFame/fetchResultsReceive';
function fetchHallOfFameResultsReceive(data: IHallsOfFameResults): IAction {
  return {
    data,
    type: FETCH_HALL_OF_FAME_RESULTS_RECEIVE,
  };
}
export const FETCH_HALL_OF_FAME_RESULTS_ERROR = 'hallOfFame/fetchResultsError';
function fetchHallOfFameResultsError(): IAction {
  return {
    type: FETCH_HALL_OF_FAME_RESULTS_ERROR,
  };
}

export function fetchBrassHallOfFameResults(
  hallOfFameId: number,
): ThunkAction<PromiseLike<void>, IStore, null, IAction> {
  return fetchHallOfFameResults(hallOfFameId, 'brass');
}

export function fetchVocalHallOfFameResults(
  hallOfFameId: number,
): ThunkAction<PromiseLike<void>, IStore, null, IAction> {
  return fetchHallOfFameResults(hallOfFameId, 'vocal');
}

export function fetchHallOfFameResults(
  hallOfFameId: number,
  category: SamiCategory,
): ThunkAction<PromiseLike<void>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchHallOfFameResultsStarted());

    return fetch(`/api/hall-of-fame/${category}/results/${hallOfFameId}`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchHallOfFameResultsError());
          console.error(`Error fetching hall of fame results: ${resp.status}`);
          return;
        }
        return resp
          .json()
          .then(
            (
              data:
                | {results: IBrassHallOfFameResult[]}
                | {results: IVocalHallOfFameResult[]},
            ) => {
              dispatch(
                fetchHallOfFameResultsReceive({
                  [category]: {
                    [hallOfFameId]: data,
                  },
                }),
              );
            },
          );
      })
      .catch((err) => {
        dispatch(fetchHallOfFameResultsError());
        console.error(`Error parsing hall of fame results: ${err.stack}`);
      });
  };
}

export function submitBrassHallOfFameVote(
  hallOfFameId: number,
  votes: IHallOfFameVote[],
): ThunkAction<PromiseLike<void>, IStore, null, IAction> {
  return submitHallOfFameVote(hallOfFameId, votes, 'brass');
}

export function submitVocalHallOfFameVote(
  hallOfFameId: number,
  votes: IHallOfFameVote[],
): ThunkAction<PromiseLike<void>, IStore, null, IAction> {
  return submitHallOfFameVote(hallOfFameId, votes, 'vocal');
}

function submitHallOfFameVote(
  hallOfFameId: number,
  votes: IHallOfFameVote[],
  category: SamiCategory,
) {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchHallOfFameDataStarted());

    return fetch(`/api/hall-of-fame/${category}/vote`, {
      body: JSON.stringify({
        hallOfFameId,
        votes,
      }),
      credentials: 'same-origin',
      method: 'POST',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchHallOfFameDataError());
          console.error(`Error submitting hall of fame vote: ${resp.status}`);
          return;
        }

        return resp.json().then((data) => {
          dispatch(fetchHallOfFameDataReceive(data));
          dispatch(fetchUserDetail());
        });
      })
      .catch((err) => {
        dispatch(fetchHallOfFameDataError());
        console.error(`Error submitting hall of fame vote: ${err.stack}`);
      });
  };
}

export const HALL_OF_FAME_ADVANCE_STARTED = 'hallOfFame/advanceStarted';
function hallOfFameAdvanceStarted(): IAction {
  return {
    type: HALL_OF_FAME_ADVANCE_STARTED,
  };
}

export const HALL_OF_FAME_ADVANCE_ERROR = 'hallOfFame/advanceError';
function hallOfFameAdvanceError(): IAction {
  return {
    type: HALL_OF_FAME_ADVANCE_ERROR,
  };
}

export const HALL_OF_FAME_ADVANCE_SUCCESS = 'hallOfFame/advanceSuccess';
function hallOfFameAdvanceSuccess(): IAction {
  return {
    type: HALL_OF_FAME_ADVANCE_SUCCESS,
  };
}

export function advanceHallOfFame(
  hallOfFameId: number,
  category: SamiCategory,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(hallOfFameAdvanceStarted());

    return fetch(`/api/hall-of-fame/${category}/advance/`, {
      body: JSON.stringify({hallOfFameId}),
      credentials: 'same-origin',
      method: 'POST',
    })
      .then((resp: Response) => {
        if (resp.status === 401 || !resp.ok) {
          dispatch(hallOfFameAdvanceError());
          return;
        }
        return resp.json().then(() => {
          dispatch(hallOfFameAdvanceSuccess());
          dispatch(fetchHallOfFameData());
        });
      })
      .catch((err) => {
        console.error(
          `Error parsing hall of fame advance detail: ${err.stack}`,
        );
      });
  };
}
