import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import store from './redux/store';
// import * as serviceWorker from './serviceWorker';

import './index.scss';

const Index: React.FunctionComponent<{}> = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <Index />
    </Provider>,
  );
}

/**
 * DALE NOTE: This service worker doesn't give as much configuration as I'd like,
 * so I've written a custom one on the server side instead
 */
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
