import {IAction} from '../store';
import {RECAPTCHA_READY} from './actions';

export interface IExternalApiState {
  recaptcha: {
    ready: boolean;
  };
}

export const initialState: IExternalApiState = {
  recaptcha: {
    ready: false,
  },
};

export default function externalApiReducer(
  state = initialState,
  action: IAction,
): IExternalApiState {
  switch (action.type) {
    case RECAPTCHA_READY:
      return {
        ...state,
        recaptcha: {
          ...state.recaptcha,
          ready: true,
        },
      };

    default:
      return state;
  }
}
