import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {
  IRegistrationData,
  IResetPasswordData,
  IUser,
  IUserUpdatePreferencesData,
  IUserUpdateProfileData,
} from '../../definitions/user';
import {pendoLogin} from '../../utils/pendo';
import {fetchBasket, setTheme} from '../actions';
import {IAction, IStore} from '../store';

export const FETCH_USER_DETAIL_STARTED = 'user/fetchDetailStarted';
function fetchUserDetailStarted(): IAction {
  return {
    type: FETCH_USER_DETAIL_STARTED,
  };
}
export const FETCH_USER_DETAIL_UNAUTHORISED = 'user/fetchDetailUnauthorised';
function fetchUserDetailUnathorised(): IAction {
  return {
    type: FETCH_USER_DETAIL_UNAUTHORISED,
  };
}
export const FETCH_USER_DETAIL_RECEIVE = 'user/fetchDetailReceive';
function fetchUserDetailReceive(
  data: IUser,
  dispatch: ThunkDispatch<IStore, null, IAction>,
): IAction {
  // Refetch basket to remove duplicates
  dispatch(fetchBasket());
  dispatch(setTheme(data.theme.id));

  pendoLogin(data);

  return {
    data,
    type: FETCH_USER_DETAIL_RECEIVE,
  };
}
export function fetchUserDetail(): ThunkAction<
  PromiseLike<any>,
  IStore,
  null,
  IAction
> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchUserDetailStarted());

    return fetch(`/api/user/`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok && resp.status !== 401) {
          dispatch(fetchUserDetailUnathorised());
          console.error(`Error fetching user detail: ${resp.status}`);
          return;
        }
        if (resp.status === 401) {
          // User isn't logged in
          dispatch(fetchUserDetailUnathorised());
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchUserDetailReceive(data, dispatch));
        });
      })
      .catch((err) => {
        dispatch(fetchUserDetailUnathorised());
        console.error(`Error parsing user detail: ${err.stack}`);
      });
  };
}

export const USER_LOGIN_STARTED = 'user/loginStarted';
function loginStarted(): IAction {
  return {
    type: USER_LOGIN_STARTED,
  };
}
export const USER_LOGIN_UNAUTHORISED = 'user/loginUnauthorised';
function loginUnauthorised(): IAction {
  return {
    type: USER_LOGIN_UNAUTHORISED,
  };
}
export const USER_LOGIN_ERROR = 'user/loginError';
function loginError(): IAction {
  return {
    type: USER_LOGIN_ERROR,
  };
}
export function login(
  email: string,
  password: string,
  remember: boolean,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(loginStarted());

    return fetch(`/api/user/`, {
      body: JSON.stringify({email, password, remember}),
      credentials: 'same-origin',
      method: 'POST',
    })
      .then((resp: Response) => {
        if (resp.status === 401) {
          // Unauthorised
          dispatch(loginUnauthorised());
          return;
        }
        if (!resp.ok) {
          console.error(`Error logging in: ${resp.status}`);
          dispatch(loginError());
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchUserDetailReceive(data, dispatch));
        });
      })
      .catch((err) => {
        dispatch(loginError());
        console.error(`Error parsing login detail: ${err.stack}`);
      });
  };
}

export const USER_MORPH_STARTED = 'user/morphStarted';
function morphStarted(): IAction {
  return {
    type: USER_MORPH_STARTED,
  };
}
export const USER_MORPH_UNAUTHORISED = 'user/morphUnauthorised';
function morphUnauthorised(): IAction {
  return {
    type: USER_MORPH_UNAUTHORISED,
  };
}
export const USER_MORPH_ERROR = 'user/morphError';
function morphError(): IAction {
  return {
    type: USER_MORPH_ERROR,
  };
}
export function morph(
  userId: string,
  onDone: () => void,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(morphStarted());

    return fetch(`/api/admin/morph`, {
      body: JSON.stringify({userId}),
      credentials: 'same-origin',
      method: 'POST',
    })
      .then((resp: Response) => {
        if (resp.status === 401) {
          // Unauthorised
          dispatch(morphUnauthorised());
          return;
        }
        if (!resp.ok) {
          console.error(`Error morphing as: ${resp.status}`);
          dispatch(morphError());
          return;
        }
        return resp.json().then((data) => {
          onDone();
          dispatch(fetchUserDetailReceive(data, dispatch));
        });
      })
      .catch((err) => {
        dispatch(morphError());
        console.error(`Error parsing morph detail: ${err.stack}`);
      });
  };
}

export const USER_REGISTRATION_STARTED = 'user/registrationStarted';
function registrationStarted(): IAction {
  return {
    type: USER_REGISTRATION_STARTED,
  };
}
export const USER_REGISTRATION_ERROR = 'user/registrationError';
function registrationError(): IAction {
  return {
    type: USER_REGISTRATION_ERROR,
  };
}
export const USER_REGISTRATION_CONFLICT = 'user/registrationConflict';
function registrationConflict(): IAction {
  return {
    type: USER_REGISTRATION_CONFLICT,
  };
}
export function register(
  registrationData: IRegistrationData,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(registrationStarted());

    return fetch(`/api/user/register`, {
      body: JSON.stringify(registrationData),
      credentials: 'same-origin',
      method: 'POST',
    })
      .then((resp: Response) => {
        if (resp.status === 401) {
          // Unauthorised
          dispatch(registrationError());
          return;
        }
        if (resp.status === 409) {
          // Conflict
          dispatch(registrationConflict());
          return;
        }
        if (!resp.ok) {
          dispatch(registrationError());
          console.error(`Error registering: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchUserDetailReceive(data, dispatch));
        });
      })
      .catch((err) => {
        dispatch(registrationError());
        console.error(`Error parsing registration detail: ${err.stack}`);
      });
  };
}

export const USER_UPDATE_PROFILE_STARTED = 'user/updateProfileStarted';
function userUpdateProfileStarted(): IAction {
  return {
    type: USER_UPDATE_PROFILE_STARTED,
  };
}
export const USER_UPDATE_PROFILE_ERROR = 'user/updateProfileError';
function userUpdateProfileError(): IAction {
  return {
    type: USER_UPDATE_PROFILE_ERROR,
  };
}
export const USER_UPDATE_PROFILE_EMAIL_CONFLICT = 'user/updateEmailConflict';
function userUpdateEmailConflict(): IAction {
  return {
    type: USER_UPDATE_PROFILE_EMAIL_CONFLICT,
  };
}
export function updateUserProfile(
  updateData: IUserUpdateProfileData,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(userUpdateProfileStarted());

    return fetch(`/api/user/update-profile`, {
      body: JSON.stringify(updateData),
      credentials: 'same-origin',
      method: 'PUT',
    })
      .then((resp: Response) => {
        if (resp.status === 409) {
          // Conflict
          dispatch(userUpdateEmailConflict());
          return;
        }
        if (!resp.ok) {
          dispatch(userUpdateProfileError());
          console.error(`Error updating user detail: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchUserDetailReceive(data, dispatch));
        });
      })
      .catch((err) => {
        dispatch(userUpdateProfileError());
        console.error(`Error parsing user detail: ${err.stack}`);
      });
  };
}

export const USER_UPDATE_PREFERENCES_STARTED = 'user/updatePreferencesStarted';
function userUpdatePreferencesStarted(): IAction {
  return {
    type: USER_UPDATE_PREFERENCES_STARTED,
  };
}
export const USER_UPDATE_PREFERENCES_ERROR = 'user/updatePreferencesError';
function userUpdatePreferencesError(): IAction {
  return {
    type: USER_UPDATE_PREFERENCES_ERROR,
  };
}
export function updateUserPreferences(
  updateData: IUserUpdatePreferencesData,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(userUpdatePreferencesStarted());

    return fetch(`/api/user/update-preferences`, {
      body: JSON.stringify(updateData),
      credentials: 'same-origin',
      method: 'PUT',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(userUpdatePreferencesError());
          console.error(`Error updating user detail: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchUserDetailReceive(data, dispatch));
        });
      })
      .catch((err) => {
        dispatch(userUpdatePreferencesError());
        console.error(`Error parsing user detail: ${err.stack}`);
      });
  };
}

export const CHANGE_PASSWORD_STARTED = 'user/changePasswordStarted';
function changePasswordStarted(): IAction {
  return {
    type: CHANGE_PASSWORD_STARTED,
  };
}
export const CHANGE_PASSWORD_UNAUTHORISED = 'user/changePasswordUnauthorised';
function changePasswordUnauthorised(): IAction {
  return {
    type: CHANGE_PASSWORD_UNAUTHORISED,
  };
}
function changePasswordByTokenUnauthorised(): IAction {
  return {
    data: {token: true},
    type: CHANGE_PASSWORD_UNAUTHORISED,
  };
}
export const CHANGE_PASSWORD_TOKEN_EXPIRED = 'user/changePasswordTokenExpired';
function changePasswordByTokenExpired(): IAction {
  return {
    type: CHANGE_PASSWORD_TOKEN_EXPIRED,
  };
}
export const CHANGE_PASSWORD_ERROR = 'user/changePasswordError';
function changePasswordError(): IAction {
  return {
    type: CHANGE_PASSWORD_ERROR,
  };
}
export const CHANGE_PASSWORD_SUCCESS = 'user/changePasswordSuccess';
function changePasswordSuccess(): IAction {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  };
}
export function changePassword(
  oldPassword: string,
  newPassword: string,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(changePasswordStarted());

    return fetch(`/api/user/change-password`, {
      body: JSON.stringify({oldPassword, newPassword}),
      credentials: 'same-origin',
      method: 'PUT',
    })
      .then((resp: Response) => {
        if (resp.status === 401) {
          dispatch(changePasswordUnauthorised());
          return;
        }
        if (!resp.ok) {
          dispatch(changePasswordError());
          console.error(`Error updating user password: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchUserDetailReceive(data, dispatch));
          dispatch(changePasswordSuccess());
        });
      })
      .catch((err) => {
        console.error(`Error parsing change password response: ${err.stack}`);
      });
  };
}
export function changePasswordByToken(
  token: string,
  newPassword: string,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(changePasswordStarted());

    return fetch(`/api/user/change-password-by-token`, {
      body: JSON.stringify({token, newPassword}),
      credentials: 'same-origin',
      method: 'PUT',
    })
      .then((resp: Response) => {
        if (resp.status === 401) {
          dispatch(changePasswordByTokenUnauthorised());
          return;
        }
        if (resp.status === 410) {
          dispatch(changePasswordByTokenExpired());
          return;
        }
        if (!resp.ok) {
          dispatch(changePasswordError());
          console.error(`Error updating user password: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(changePasswordSuccess());
        });
      })
      .catch((err) => {
        console.error(`Error parsing change password response: ${err.stack}`);
      });
  };
}

export const USER_RESET_PASSWORD_STARTED = 'user/resetPasswordStarted';
function resetPasswordStarted(): IAction {
  return {
    type: USER_RESET_PASSWORD_STARTED,
  };
}
export const USER_RESET_PASSWORD_ERROR = 'user/resetPasswordError';
function resetPasswordError(): IAction {
  return {
    type: USER_RESET_PASSWORD_ERROR,
  };
}
export const USER_RESET_PASSWORD_CONFLICT = 'user/resetPasswordConflict';
function resetPasswordConflict(): IAction {
  return {
    type: USER_RESET_PASSWORD_CONFLICT,
  };
}
export const USER_RESET_PASSWORD_SUCCESS = 'user/resetPasswordSuccess';
function resetPasswordSuccess(): IAction {
  return {
    type: USER_RESET_PASSWORD_SUCCESS,
  };
}
export function resetPassword(
  resetPasswordData: IResetPasswordData,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(resetPasswordStarted());

    return fetch(`/api/user/reset-password`, {
      body: JSON.stringify(resetPasswordData),
      credentials: 'same-origin',
      method: 'POST',
    })
      .then((resp: Response) => {
        if (resp.status === 401) {
          // Unauthorised
          dispatch(resetPasswordError());
          return;
        }
        if (resp.status === 409) {
          // Conflict
          dispatch(resetPasswordConflict());
          return;
        }
        if (!resp.ok) {
          dispatch(resetPasswordError());
          console.error(`Error resetting password: ${resp.status}`);
          return;
        }
        return resp.json().then(() => {
          dispatch(resetPasswordSuccess());
        });
      })
      .catch((err) => {
        console.error(`Error parsing reset password detail: ${err.stack}`);
      });
  };
}

export function isSuperUser(): ThunkAction<boolean, IStore, null, IAction> {
  return (
    dispatch: ThunkDispatch<IStore, null, IAction>,
    getState: () => IStore,
  ) => {
    const state = getState();
    return !!(
      state.user.userData.detail &&
      state.user.userData.detail.isAdmin &&
      state.dom.debugMode
    );
  };
}

export const SUBSCRIPTION_VOUCHER_APPLY_STARTED =
  'user/subscriptionVoucherApplyStarted';
function subscriptionVoucherApplyStarted(): IAction {
  return {
    type: SUBSCRIPTION_VOUCHER_APPLY_STARTED,
  };
}
export const SUBSCRIPTION_VOUCHER_APPLY_ERROR =
  'user/subscriptionVoucherApplyError';
function subscriptionVoucherApplyError(): IAction {
  return {
    type: SUBSCRIPTION_VOUCHER_APPLY_ERROR,
  };
}
export const SUBSCRIPTION_VOUCHER_APPLY_FORBIDDEN =
  'user/subscriptionVoucherApplyForbidden';
function subscriptionVoucherApplyForbidden(): IAction {
  return {
    type: SUBSCRIPTION_VOUCHER_APPLY_FORBIDDEN,
  };
}
export const SUBSCRIPTION_VOUCHER_APPLY_CONFLICT =
  'user/subscriptionVoucherApplyConflict';
function subscriptionVoucherApplyConflict(): IAction {
  return {
    type: SUBSCRIPTION_VOUCHER_APPLY_CONFLICT,
  };
}
export const SUBSCRIPTION_VOUCHER_APPLY_BAD_CODE =
  'user/subscriptionVoucherApplyBadCode';
function subscriptionVoucherApplyBadCode(): IAction {
  return {
    type: SUBSCRIPTION_VOUCHER_APPLY_BAD_CODE,
  };
}
export const SUBSCRIPTION_VOUCHER_APPLY_EXPIRED =
  'user/subscriptionVoucherApplyExpired';
function subscriptionVoucherApplyExpired(): IAction {
  return {
    type: SUBSCRIPTION_VOUCHER_APPLY_EXPIRED,
  };
}
export const SUBSCRIPTION_VOUCHER_APPLY_SUCCESS =
  'user/subscriptionVoucherApplySuccess';
function subscriptionVoucherApplySuccess(): IAction {
  return {
    type: SUBSCRIPTION_VOUCHER_APPLY_SUCCESS,
  };
}
export function applySubscriptionVoucher(voucherCode: string) {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(subscriptionVoucherApplyStarted());

    return fetch(`/api/user/apply-subscription-voucher`, {
      body: JSON.stringify({voucherCode}),
      credentials: 'same-origin',
      method: 'POST',
    })
      .then((resp: Response) => {
        if (resp.status === 400) {
          // Bad input
          dispatch(subscriptionVoucherApplyBadCode());
          return;
        }
        if (resp.status === 401) {
          // Unauthorised
          dispatch(subscriptionVoucherApplyError());
          return;
        }
        if (resp.status === 403) {
          // Forbidden
          dispatch(subscriptionVoucherApplyForbidden());
          return;
        }
        if (resp.status === 409) {
          // Conflict
          dispatch(subscriptionVoucherApplyConflict());
          return;
        }
        if (resp.status === 410) {
          // Expired Subscription
          dispatch(subscriptionVoucherApplyExpired());
          return;
        }
        if (!resp.ok) {
          dispatch(subscriptionVoucherApplyError());
          console.error(`Error applying subscription voucher: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchUserDetailReceive(data, dispatch));
          dispatch(subscriptionVoucherApplySuccess());
        });
      })
      .catch((err) => {
        console.error(
          `Error parsing subscription voucher detail: ${err.stack}`,
        );
      });
  };
}
