import removeAccents from 'remove-accents';

function textCompare(value: string, filter: string): boolean {
  // Ignore case
  value = value.toLowerCase();
  filter = filter.toLowerCase();

  // Ignore accents
  value = removeAccents(value);
  filter = removeAccents(filter);

  // Ignore apostrophes / quotes
  value = value.replace(/('|‘|’|"|“|”)/g, '');
  filter = filter.replace(/('|‘|’|"|“|”)/g, '');

  const tokens = filter.split(' ');

  // If any token isn't found in the column, there's no match
  return tokens.every((token) => {
    return value.indexOf(token) > -1;
  });
}

export default textCompare;
