import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {ITheme} from '../../definitions/dom';
import {IAction, IStore} from '../store';

export const DOM_RESIZE = 'dom/resize';
export function domResize(): IAction {
  return {
    type: DOM_RESIZE,
  };
}

export const SET_DEBUG_MODE = 'dom/setDebugMode';
export function setDebugMode(debugMode: boolean): IAction {
  return {
    data: debugMode,
    type: SET_DEBUG_MODE,
  };
}

export const SET_INSTALL_PROMPT = 'dom/setInstallPrompt';
export function setInstallPrompt(installPrompt: any): IAction {
  return {
    data: installPrompt,
    type: SET_INSTALL_PROMPT,
  };
}

export const FETCH_THEMES_STARTED = 'dom/fetchThemesStarted';
function fetchThemesStarted(): IAction {
  return {
    type: FETCH_THEMES_STARTED,
  };
}
export const FETCH_THEMES_RECEIVE = 'dom/fetchThemesReceive';
function fetchThemesReceive(data: ITheme[]): IAction {
  return {
    data,
    type: FETCH_THEMES_RECEIVE,
  };
}
export const FETCH_THEMES_ERROR = 'dom/fetchThemesError';
function fetchThemesError(): IAction {
  return {
    type: FETCH_THEMES_ERROR,
  };
}
export function fetchThemes(): ThunkAction<
  PromiseLike<any>,
  IStore,
  null,
  IAction
> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchThemesStarted());

    return fetch(`/api/themes`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchThemesError());
          console.error(`Error fetching themes: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchThemesReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchThemesError());
        console.error(`Error parsing themes: ${err.stack}`);
      });
  };
}

export const SET_THEME = 'dom/setTheme';
export function setTheme(themeId: number): IAction {
  return {
    data: themeId,
    type: SET_THEME,
  };
}
