import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {IUser} from '../definitions/user';
import {IStore} from '../redux/store';

import './footer.scss';

interface IFooterStateProps {
  user: IUser | null;
}

class Footer extends React.PureComponent<IFooterStateProps, {}> {
  public render() {
    return (
      <>
        {this.renderMorphedNotice()}
        <footer>
          <div className="copyright">
            &copy; 2015 - {new Date().getFullYear()}
          </div>
          <div className="legal">
            <Link to="/faq">FAQ</Link>
            <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </footer>
      </>
    );
  }

  private renderMorphedNotice(): React.ReactNode {
    if (!this.props.user?.isMorphed) {
      return null;
    }

    return (
      <div className="morph-banner">
        You are currently morphed. Please <a href="/unmorph">unmorph</a> as soon
        as possible.
      </div>
    );
  }
}

const mapStateToProps = (state: IStore): IFooterStateProps => ({
  user: state.user.userData.detail,
});

export default connect<IFooterStateProps, {}, any, IStore>(mapStateToProps)(
  Footer,
);
