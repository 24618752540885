import {ITheme} from '../../definitions/dom';
import {determineBreakpoint, IBreakpointDetail} from '../../utils/responsive';
import {IAction} from '../store';
import {
  DOM_RESIZE,
  FETCH_THEMES_ERROR,
  FETCH_THEMES_RECEIVE,
  FETCH_THEMES_STARTED,
  SET_DEBUG_MODE,
  SET_INSTALL_PROMPT,
  SET_THEME,
} from './actions';

export interface IDomState {
  breakpoint: IBreakpointDetail;
  debugMode: boolean;
  installPrompt: any;
  themesData: {
    detail: ITheme[];
    error: boolean;
    fetching: boolean;
    selectedThemeId: number;
  };
}

export const initialState: IDomState = {
  breakpoint: determineBreakpoint(),
  debugMode: false,
  installPrompt: null,
  themesData: {
    detail: [],
    error: false,
    fetching: false,
    selectedThemeId: 1,
  },
};

export default function domReducer(
  state = initialState,
  action: IAction,
): IDomState {
  switch (action.type) {
    case DOM_RESIZE:
      return {
        ...state,
        breakpoint: determineBreakpoint(),
      };

    case FETCH_THEMES_STARTED:
      return {
        ...state,
        themesData: {
          ...state.themesData,
          error: false,
          fetching: true,
        },
      };

    case FETCH_THEMES_RECEIVE: {
      return {
        ...state,
        themesData: {
          ...state.themesData,
          detail: action.data,
          error: false,
          fetching: false,
        },
      };
    }

    case FETCH_THEMES_ERROR: {
      return {
        ...state,
        themesData: {
          ...state.themesData,
          error: true,
          fetching: false,
        },
      };
    }

    case SET_DEBUG_MODE:
      return {
        ...state,
        debugMode: action.data,
      };

    case SET_INSTALL_PROMPT:
      return {
        ...state,
        installPrompt: action.data,
      };

    case SET_THEME:
      const newThemeId = action.data;

      // Although we store the selected theme in the state,
      // we need to set it on the body element,
      // which is outside of React's root.
      // So just manually set it here.
      state.themesData.detail.forEach((theme: ITheme) => {
        document.body.classList.remove(`theme-${theme.name}`);

        if (theme.id === newThemeId) {
          document.body.classList.add(`theme-${theme.name}`);
        }
      });

      return {
        ...state,
        themesData: {
          ...state.themesData,
          selectedThemeId: newThemeId,
        },
      };

    default:
      return state;
  }
}
