import React from 'react';
import {Helmet} from 'react-helmet';

export interface IMetaProps {
  description: string;
  title: string;
}

const Meta: React.FunctionComponent<IMetaProps> = (props) => {
  const url = document.location
    ? `${document.location.protocol}//${document.location.host}${document.location.pathname}`
    : '';

  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />

      <meta property="og:title" content={props.title} />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={props.description} />
      <meta
        property="og:image"
        content="https://samusicindex.com/img/social.png"
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_GB" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@samusicindex" />
    </Helmet>
  );
};

export default Meta;
