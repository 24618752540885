import {combineReducers} from 'redux';
import adminReducer from './admin/reducer';
import basketReducer from './basket/reducer';
import brassReducer from './brass/reducer';
import domReducer from './dom/reducer';
import externalApiReducer from './externalApis/reducer';
import hallOfFameReducer from './hallOfFame/reducer';
import userReducer from './user/reducer';
import vocalReducer from './vocal/reducer';

import type {IAdminState} from './admin/reducer';
import type {IBasketState} from './basket/reducer';
import type {IBrassState} from './brass/reducer';
import type {IDomState} from './dom/reducer';
import type {IExternalApiState} from './externalApis/reducer';
import type {IHallOfFameState} from './hallOfFame/reducer';
import type {IUserState} from './user/reducer';
import type {IVocalState} from './vocal/reducer';

export {initialState as initialAdminState} from './admin/reducer';
export type {IAdminState};

export {initialState as initialBasketState} from './basket/reducer';
export type {IBasketState};

export {initialState as initialBrassState} from './brass/reducer';
export type {IBrassState};

export {initialState as initialDomState} from './dom/reducer';
export type {IDomState};

export {initialState as initialExternalApiState} from './externalApis/reducer';
export type {IExternalApiState};

export {initialState as initialHallOfFameState} from './hallOfFame/reducer';
export type {IHallOfFameState};

export {initialState as initialUserState} from './user/reducer';
export type {IUserState};

export {initialState as initialVocalState} from './vocal/reducer';
export type {IVocalState};

const reducer = combineReducers({
  admin: adminReducer,
  basket: basketReducer,
  brass: brassReducer,
  dom: domReducer,
  externalApis: externalApiReducer,
  hallOfFame: hallOfFameReducer,
  user: userReducer,
  vocal: vocalReducer,
});

export default reducer;
