import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {IMinSeriesDetail} from '../../definitions/shared';
import {
  IMinVocalData,
  IMinVocalDetail,
  IMinVocalSeriesData,
  IVocalMp3Album,
  VocalSort,
} from '../../definitions/vocal';
import {IAction, IStore} from '../store';

interface IFetchVocalGridDataOptions {
  includeHofData: boolean;
}

export const FETCH_VOCAL_GRID_DATA_STARTED = 'vocal/fetchGridStarted';
function fetchVocalGridDataStarted(): IAction {
  return {
    type: FETCH_VOCAL_GRID_DATA_STARTED,
  };
}
export const FETCH_VOCAL_GRID_DATA_RECEIVE = 'vocal/fetchGridReceive';
function fetchVocalGridDataReceive(
  data: IMinVocalData,
  options?: Partial<IFetchVocalGridDataOptions>,
): IAction {
  return {
    data,
    includeHofData: !!options?.includeHofData,
    type: FETCH_VOCAL_GRID_DATA_RECEIVE,
  };
}
export const FETCH_VOCAL_GRID_DATA_ERROR = 'vocal/fetchGridError';
function fetchVocalGridDataError(): IAction {
  return {
    type: FETCH_VOCAL_GRID_DATA_ERROR,
  };
}
export function fetchVocalGridData(
  options?: Partial<IFetchVocalGridDataOptions>,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchVocalGridDataStarted());

    return fetch(`/api/vocal`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchVocalGridDataError());
          console.error(`Error fetching vocal data: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchVocalGridDataReceive(data, options));
        });
      })
      .catch((err) => {
        dispatch(fetchVocalGridDataError());
        console.error(`Error parsing vocal data: ${err.stack}`);
      });
  };
}

export const FETCH_VOCAL_SERIES_STARTED = 'vocal/fetchSeriesStarted';
function fetchVocalSeriesStarted(): IAction {
  return {
    type: FETCH_VOCAL_SERIES_STARTED,
  };
}
export const FETCH_VOCAL_SERIES_RECEIVE = 'vocal/fetchSeriesReceive';
function fetchVocalSeriesReceive(data: IMinVocalSeriesData): IAction {
  return {
    data,
    type: FETCH_VOCAL_SERIES_RECEIVE,
  };
}
export const FETCH_VOCAL_SERIES_ERROR = 'vocal/fetchSeriesError';
function fetchVocalSeriesError(): IAction {
  return {
    type: FETCH_VOCAL_SERIES_ERROR,
  };
}
export function fetchVocalSeries(): ThunkAction<
  PromiseLike<any>,
  IStore,
  null,
  IAction
> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchVocalSeriesStarted());

    return fetch(`/api/vocal/series`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchVocalSeriesError());
          console.error(`Error fetching vocal series data: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchVocalSeriesReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchVocalSeriesError());
        console.error(`Error parsing vocal series data: ${err.stack}`);
      });
  };
}

export const FETCH_VOCAL_DETAIL_STARTED = 'vocal/fetchDetailStarted';
function fetchVocalDetailStarted(): IAction {
  return {
    type: FETCH_VOCAL_DETAIL_STARTED,
  };
}
export const FETCH_VOCAL_DETAIL_RECEIVE = 'vocal/fetchDetailReceive';
function fetchVocalDetailReceive(data: IMinVocalDetail): IAction {
  return {
    data,
    type: FETCH_VOCAL_DETAIL_RECEIVE,
  };
}
export const FETCH_VOCAL_DETAIL_ERROR = 'vocal/fetchDetailError';
function fetchVocalDetailError(): IAction {
  return {
    type: FETCH_VOCAL_DETAIL_ERROR,
  };
}
export function fetchVocalDetail(
  publicationId: string,
  onDone: () => void,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchVocalDetailStarted());

    return fetch(`/api/vocal/detail/${publicationId}`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchVocalDetailError());
          console.error(`Error fetching vocal detail: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchVocalDetailReceive(data));
          onDone();
        });
      })
      .catch((err) => {
        dispatch(fetchVocalDetailError());
        console.error(`Error parsing vocal detail: ${err.stack}`);
      });
  };
}

export const FETCH_VOCAL_MP3_ALBUM_STARTED = 'vocal/fetchMp3AlbumStarted';
function fetchVocalMp3AlbumStarted(): IAction {
  return {
    type: FETCH_VOCAL_MP3_ALBUM_STARTED,
  };
}
export const FETCH_VOCAL_MP3_ALBUM_RECEIVE = 'vocal/fetchMp3AlbumReceive';
function fetchVocalMp3AlbumReceive(data: IVocalMp3Album): IAction {
  return {
    data,
    type: FETCH_VOCAL_MP3_ALBUM_RECEIVE,
  };
}
export const FETCH_VOCAL_MP3_ALBUM_ERROR = 'vocal/fetchVocalMp3AlbumError';
function fetchVocalMp3AlbumError(): IAction {
  return {
    type: FETCH_VOCAL_MP3_ALBUM_ERROR,
  };
}
export function fetchVocalMp3Album(
  mp3AlbumId: number,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchVocalMp3AlbumStarted());

    return fetch(`/api/vocal/mp3-album/${mp3AlbumId}`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchVocalMp3AlbumError());
          console.error(`Error fetching vocal mp3 album: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchVocalMp3AlbumReceive(data));
        });
      })
      .catch((err) => {
        dispatch(fetchVocalMp3AlbumError());
        console.error(`Error parsing vocal mp3 album: ${err.stack}`);
      });
  };
}

export const RESET_VOCAL_DETAIL = 'vocal/detail/reset';
export function resetVocalDetail(): IAction {
  return {
    type: RESET_VOCAL_DETAIL,
  };
}

export const RESET_VOCAL_MP3_ALBUM = 'vocal/mp3Album/reset';
export function resetVocalMp3Album(): IAction {
  return {
    type: RESET_VOCAL_MP3_ALBUM,
  };
}

export const FILTER_VOCAL = 'vocal/filter';
export function filterVocal(key: string, value: boolean | string): IAction {
  return {
    key,
    type: FILTER_VOCAL,
    value,
  };
}

export const FILTER_VOCAL_PRIME = 'vocal/filter/prime';
export function primeVocalFilter(
  key: string,
  value: boolean | string,
): IAction {
  return {
    key,
    type: FILTER_VOCAL_PRIME,
    value,
  };
}

export const FILTER_VOCAL_EXECUTE = 'vocal/filter/execute';
export function executeVocalFilters(): IAction {
  return {
    type: FILTER_VOCAL_EXECUTE,
  };
}

export const FILTER_VOCAL_RESET = 'vocal/filter/reset';
export function resetVocalFilters(): IAction {
  return {
    type: FILTER_VOCAL_RESET,
  };
}

export const SORT_VOCAL = 'vocal/sort';
export function sortVocal(vocalSort: VocalSort): IAction {
  return {
    type: SORT_VOCAL,
    vocalSort,
  };
}

export const SORT_VOCAL_PRIME = 'vocal/sort/prime';
export function primeVocalSort(vocalSort: VocalSort): IAction {
  return {
    type: SORT_VOCAL_PRIME,
    vocalSort,
  };
}

export const SORT_VOCAL_EXECUTE = 'vocal/sort/execute';
export function executeVocalSort(): IAction {
  return {
    type: SORT_VOCAL_EXECUTE,
  };
}

export const UPDATE_VOCAL_DETAIL_INDEX = '/vocal/updateDetailIndex';
export function updateVocalDetailIndex(index: number): IAction {
  return {
    index,
    type: UPDATE_VOCAL_DETAIL_INDEX,
  };
}

export const FETCH_VOCAL_SERIES_DETAIL_STARTED =
  'vocal/fetchSeriesDetailStarted';
function fetchVocalSeriesDetailStarted(): IAction {
  return {
    type: FETCH_VOCAL_SERIES_DETAIL_STARTED,
  };
}
export const FETCH_VOCAL_SERIES_DETAIL_RECEIVE =
  'vocal/fetchSeriesDetailReceive';
function fetchVocalSeriesDetailReceive(data: IMinSeriesDetail): IAction {
  return {
    data,
    type: FETCH_VOCAL_SERIES_DETAIL_RECEIVE,
  };
}
export const FETCH_VOCAL_SERIES_DETAIL_ERROR = 'vocal/fetchSeriesDetailError';
function fetchVocalSeriesDetailError(): IAction {
  return {
    type: FETCH_VOCAL_SERIES_DETAIL_ERROR,
  };
}
export function fetchVocalSeriesDetail(
  seriesId: string,
  onDone: () => void,
): ThunkAction<PromiseLike<any>, IStore, null, IAction> {
  return (dispatch: ThunkDispatch<IStore, null, IAction>) => {
    dispatch(fetchVocalSeriesDetailStarted());

    return fetch(`/api/vocal/series-detail/${seriesId}`, {
      credentials: 'same-origin',
      method: 'GET',
    })
      .then((resp: Response) => {
        if (!resp.ok) {
          dispatch(fetchVocalSeriesDetailError());
          console.error(`Error fetching vocal series detail: ${resp.status}`);
          return;
        }
        return resp.json().then((data) => {
          dispatch(fetchVocalSeriesDetailReceive(data));
          onDone();
        });
      })
      .catch((err) => {
        dispatch(fetchVocalSeriesDetailError());
        console.error(`Error parsing vocal series detail: ${err.stack}`);
      });
  };
}

export const RESET_VOCAL_SERIES_DETAIL = 'vocal/seriesDetail/reset';
export function resetVocalSeriesDetail(): IAction {
  return {
    type: RESET_VOCAL_SERIES_DETAIL,
  };
}
