type ResponsiveBreakpoint =
  | 'fullSize'
  | 'wideDesktop'
  | 'desktop'
  | 'tablet'
  | 'widePhone'
  | 'phone';
export interface IBreakpointDetail {
  headerHeight: number;
  label: ResponsiveBreakpoint;
  minWidth: number;
}

export function determineBreakpoint(): IBreakpointDetail {
  if (document.documentElement) {
    const {clientWidth} = document.documentElement;

    if (clientWidth >= 1200) {
      return {
        headerHeight: 71,
        label: 'fullSize',
        minWidth: 1200,
      };
    } else if (clientWidth >= 960) {
      return {
        headerHeight: 71,
        label: 'wideDesktop',
        minWidth: 960,
      };
    } else if (clientWidth >= 768) {
      return {
        headerHeight: 71,
        label: 'desktop',
        minWidth: 768,
      };
    } else if (clientWidth >= 568) {
      return {
        headerHeight: 60,
        label: 'tablet',
        minWidth: 568,
      };
    } else if (clientWidth >= 480) {
      return {
        headerHeight: 60,
        label: 'widePhone',
        minWidth: 480,
      };
    }
  }

  return {
    headerHeight: 60,
    label: 'phone',
    minWidth: 320,
  };
}

export function isExtraLarge(breakpoint: IBreakpointDetail) {
  return breakpoint.label === 'fullSize' || breakpoint.label === 'wideDesktop';
}

export function isLarge(breakpoint: IBreakpointDetail) {
  return isExtraLarge(breakpoint) || breakpoint.label === 'desktop';
}

export function isPhoneSize(breakpoint: IBreakpointDetail) {
  return breakpoint.label === 'widePhone' || breakpoint.label === 'phone';
}

export function isSmall(breakpoint: IBreakpointDetail) {
  return isPhoneSize(breakpoint) || breakpoint.label === 'tablet';
}
